import { useMemo } from "react";
import { useIntl } from "react-intl";

import {
  useHasFunctionality,
  useHasFunctionalityWithoutScope,
} from "hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "types/functionalities";

import { adminSuiteConfig } from "../../../../admin-suite.config";
import { IChapter } from "../chapter-finder.types";

export const useFinancialsChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: currenciesChapterAllowed } = useHasFunctionality(
    Functionalities.CURRENCIES,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: exportLogsChapterAllowed } = useHasFunctionality(
    Functionalities.FINANCIAL_PERIOD_EXPORTS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: generalLedgerAccountsChapterAllowed } = useHasFunctionality(
    Functionalities.ACCOUNTS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: paymentMethodsChapterAllowed } = useHasFunctionality(
    Functionalities.PAYMENT_METHODS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: paymentTypesChapterAllowed } = useHasFunctionality(
    Functionalities.PAYMENT_TYPES,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: priceListsChapterAllowed } = useHasFunctionality(
    Functionalities.PRICING,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: invoicesChapterAllowed } = useHasFunctionality(
    Functionalities.INVOICES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: taxRatesChapterAllowed } = useHasFunctionality(
    Functionalities.TAX_RATES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: paymentSettingsChapterAllowed } = useHasFunctionality(
    Functionalities.ORGANIZATION_UNIT_SET_PAYMENT_TYPES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: incomeExpensesChapterAllowed } = useHasFunctionality(
    Functionalities.CASH_EXPENSE_TYPES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: giftcardsChaterAllowed } = useHasFunctionality(
    Functionalities.GIFT_CARD_CONFIGURATIONS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: financialPeriodsAllowed } = useHasFunctionality(
    Functionalities.FINANCIAL_PERIODS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: paymentSettlementsAllowed } = useHasFunctionalityWithoutScope(
    Functionalities.PAYMENT_SETTLEMENTS,
  );

  const { functionalityAllowed: cookbookAllowed } = useHasFunctionality(
    Functionalities.COOKBOOK,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: cookbookRecipesV2Allowed } = useHasFunctionality(
    Functionalities.SCRIPTS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: financialEventsAllowed } = useHasFunctionality(
    Functionalities.FINANCIAL_EVENTS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: generalLedgersAllowed } = useHasFunctionality(
    Functionalities.GENERAL_LEDGERS,
    FunctionalityScope.View,
  );

  const financialsChapters = useMemo<IChapter[]>(() => {
    let chapters: IChapter[] = [];

    if (invoicesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.invoices",
            defaultMessage: "Invoices",
          }),
          path: "/invoices",
        },
      ];
    }

    if (
      (paymentMethodsChapterAllowed && paymentTypesChapterAllowed) ||
      paymentSettingsChapterAllowed
    ) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.payment-configuration",
            defaultMessage: "Payment configuration",
          }),
          path: "/payment",
        },
      ];
    }

    if (priceListsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.price-lists",
            defaultMessage: "Price lists",
          }),
          path: "/price-lists",
        },
      ];
    }

    if (currenciesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.currencies",
            defaultMessage: "Currencies",
          }),
          path: "/currencies",
        },
      ];
    }

    if (exportLogsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.export-logs",
            defaultMessage: "Export logs",
          }),
          path: "/export-logs",
        },
      ];
    }

    if (taxRatesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.tax-management",
            defaultMessage: "Tax management",
          }),
          path: "/tax-management",
        },
      ];
    }

    if (generalLedgerAccountsChapterAllowed || generalLedgersAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.label.general-ledgers-and-accounts",
            defaultMessage: "General ledgers & accounts",
          }),
          path: "/general-ledgers-and-accounts",
        },
      ];
    }

    if (financialPeriodsAllowed) {
      // Financial periods
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.financial-periods",
            defaultMessage: "Financial periods",
          }),
          path: "/financial-periods",
        },
      ];
    }

    if (incomeExpensesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.income-expenses",
            defaultMessage: "Income and expenses",
          }),
          path: "/income-expense",
        },
      ];
    }

    if (giftcardsChaterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.label.giftcard-configuration",
            defaultMessage: "Giftcard configuration",
          }),
          path: "/giftcards",
        },
      ];
    }

    if (paymentSettlementsAllowed) {
      // Settlements (Admin 1.0 redirect)
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.financials.chapters.settlements",
            defaultMessage: "Settlements",
          }),
          path: "/settlements",
        },
      ];
    }

    if (cookbookAllowed && adminSuiteConfig.cookbookV1) {
      chapters = [
        ...chapters,
        {
          title: `${intl.formatMessage({
            id: "generic.label.cookbook-recipes",
            defaultMessage: "Cookbook recipes",
          })} (1.0)`,
          path: "/cookbook-recipes-v1",
        },
      ];
    }

    if (cookbookRecipesV2Allowed && adminSuiteConfig.cookbookV2) {
      chapters = [
        ...chapters,
        {
          title: `${intl.formatMessage({
            id: "generic.label.cookbook-recipes",
            defaultMessage: "Cookbook recipes",
          })} (2.0)`,
          path: "/cookbook-recipes",
        },
      ];
    }

    if (financialEventsAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.label.cookbook-events",
            defaultMessage: "Cookbook events",
          }),
          path: "/cookbook-events",
        },
      ];
    }

    return chapters;
  }, [
    invoicesChapterAllowed,
    paymentMethodsChapterAllowed,
    paymentTypesChapterAllowed,
    paymentSettingsChapterAllowed,
    priceListsChapterAllowed,
    currenciesChapterAllowed,
    exportLogsChapterAllowed,
    taxRatesChapterAllowed,
    generalLedgerAccountsChapterAllowed,
    generalLedgersAllowed,
    financialPeriodsAllowed,
    incomeExpensesChapterAllowed,
    giftcardsChaterAllowed,
    paymentSettlementsAllowed,
    cookbookAllowed,
    cookbookRecipesV2Allowed,
    financialEventsAllowed,
    intl,
  ]);

  return financialsChapters;
};
