import { ChangeEvent, ReactNode, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { OrganizationUnit } from "@springtree/eva-suite-uri-parser";
import { Field, FieldProps, FormikHelpers } from "formik";

import OpenInNewTabActionButtonLink from "components/shared/action-button-links/open-in-new-tab-action-button-link";
import Grid from "components/suite-ui/grid";
import RadioButtonList from "components/suite-ui/radio-button-list";
import RadioButtonLabel from "components/suite-ui/radio-button-list/radio-button-label";
import Text from "components/suite-ui/text";
import useBuildIntentUrl from "hooks/suite-react-hooks/use-build-intent-url";

import { EVAFormik } from "../eva-formik";
import { FormikInput } from "../formik-inputs";
import { useGenerateOUSetSearchListField } from "../organization-unit-set-search-list-field";
import { OrganizationUnitSetType } from "../utils/types";

import { IAdHocSetFormValues } from "./types";
import useAdHocSetFormValidationSchema from "./use-adhoc-set-form-validation-schema";
import useCreateAdHocOUSet from "./use-create-adhoc-ouset";

interface ICreateOUSetFormActionsAreaProps {
  isSubmitting: boolean;
  onCancel?: () => void;
  submitForm: (() => Promise<void>) & (() => Promise<any>);
}

interface ICreateOUSetFormProps {
  onCancel?: () => void;
  onCreated?: (newID: number | undefined) => void;
  formActionArea: (props: ICreateOUSetFormActionsAreaProps) => ReactNode;
  inModal?: boolean;
}
/**
 * Ready to use form for creating Custom and AdHoc OUSets
 */
export const CreateOUSetForm = ({
  formActionArea,
  inModal,
  onCancel,
  onCreated,
}: ICreateOUSetFormProps) => {
  const intl = useIntl();
  const ouSetUrl = useBuildIntentUrl(OrganizationUnit.CreateSet, "ORGANIZATIONS");

  const createAdHocOUSet = useCreateAdHocOUSet();

  const handleSubmit = useCallback(
    (values: IAdHocSetFormValues, formikHelpers: FormikHelpers<IAdHocSetFormValues>) => {
      createAdHocOUSet(values, (ID) => {
        onCreated?.(ID);
        formikHelpers.setSubmitting(false);
      });
    },
    [createAdHocOUSet, onCreated],
  );

  const validationSchema = useAdHocSetFormValidationSchema();

  const { GeneratedMultiOUSetIDSearchListFieldWithFilters } = useGenerateOUSetSearchListField({
    filters: {
      Types: [OrganizationUnitSetType.System as number, OrganizationUnitSetType.Custom as number],
    },
  });

  return (
    <>
      <div className="p-5">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Text variant="h2">
              <FormattedMessage
                id="create-ouset-form.title"
                defaultMessage="Create organization unit set"
              />
            </Text>
          </Grid>
          {ouSetUrl ? (
            <Grid item>
              <OpenInNewTabActionButtonLink to={ouSetUrl} />
            </Grid>
          ) : null}
        </Grid>
      </div>
      <EVAFormik<IAdHocSetFormValues>
        initialValues={{
          type: 1,
          name: undefined,
          excludedOUSetIDs: undefined,
          includedOUSetIDs: undefined,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, submitForm }) => (
          <>
            <div className="px-5 pb-5">
              <div className="mb-5">
                <FormikInput
                  name="name"
                  autoComplete="off"
                  label={intl.formatMessage({
                    id: "generic.label.name",
                    defaultMessage: "Name",
                  })}
                />
              </div>
              <div className="mb-5">
                <GeneratedMultiOUSetIDSearchListFieldWithFilters.Formik
                  disablePopoverPortal={!!inModal}
                  name="includedOUSetIDs"
                  label={intl.formatMessage({
                    id: "create-ouset-form.included-sets.label",
                    defaultMessage: "Include organization unit set",
                  })}
                />
              </div>
              <div className="mb-5">
                <GeneratedMultiOUSetIDSearchListFieldWithFilters.Formik
                  disablePopoverPortal={!!inModal}
                  name="excludedOUSetIDs"
                  label={intl.formatMessage({
                    id: "create-ouset-form.excluded-sets.label",
                    defaultMessage: "Exclude organization unit set",
                  })}
                />
              </div>
              <div className="mb-5">
                <Field name="type">
                  {(
                    props: FieldProps<OrganizationUnitSetType | undefined, IAdHocSetFormValues>,
                  ) => (
                    <div className="mt-5">
                      <RadioButtonList
                        {...props.field}
                        selectedValue={props.field.value?.toString() ?? ""}
                        handleSelectedValueChange={(
                          _event: ChangeEvent<HTMLInputElement>,
                          newValue: string,
                        ) => {
                          props.form.setFieldValue("type", parseInt(newValue, 10));
                        }}
                      >
                        <RadioButtonLabel
                          label={intl.formatMessage({
                            id: "create-ouset-form.ouset-type.custom.label",
                            defaultMessage: "Reuse custom organization unit set (save)",
                          })}
                          value={OrganizationUnitSetType.Custom.toString()}
                          activeValue={props.field.value?.toString() ?? ""}
                        />

                        <RadioButtonLabel
                          label={intl.formatMessage({
                            id: "create-ouset-form.ouset-type.ad-hoc.label",
                            defaultMessage:
                              "Only use custom organization unit set for this selection",
                          })}
                          value={OrganizationUnitSetType.AdHoc.toString()}
                          activeValue={props.field.value?.toString() ?? ""}
                        />
                      </RadioButtonList>
                    </div>
                  )}
                </Field>
              </div>
            </div>
            {formActionArea({ onCancel, submitForm, isSubmitting })}
          </>
        )}
      </EVAFormik>
    </>
  );
};
