import { LoyaltyProgramOptions } from "./loyalty-program-form";

export const shouldEnableLoyaltyProgramLoyaltyPoints = (
  loyaltyProgramOptions?: EVA.Core.LoyaltyProgramOptions,
) =>
  loyaltyProgramOptions !== undefined &&
  (loyaltyProgramOptions & LoyaltyProgramOptions.EnableLoyaltyPoints) ===
    LoyaltyProgramOptions.EnableLoyaltyPoints;

export const shouldEnableLoyaltyProgramPaymentTypes = (
  loyaltyProgramOptions?: EVA.Core.LoyaltyProgramOptions,
) =>
  loyaltyProgramOptions !== undefined &&
  (loyaltyProgramOptions & LoyaltyProgramOptions.EnablePaymentMethods) ===
    LoyaltyProgramOptions.EnablePaymentMethods;
