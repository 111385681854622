import { useMemo } from "react";

import { PIM } from "@springtree/eva-services-pim";

import {
  listProductPropertyTypesQuery,
  useListProductPropertyTypesQuery,
} from "models/product-search-properties";
import {
  ProductPropertyTypeSearchTypeMatchingMethod,
  ProductPropertyTypeSearchTypes,
} from "types/enums";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "util/lyra-search-list-field-generator/search-list-field-generator.types";

export type TProductSearchPropertySearchListFieldItem = {
  ID: string;
  Name: string;
};

function useProductSearchPropertyById(
  id: string | undefined,
  currentListItems: TProductSearchPropertySearchListFieldItem[] | undefined,
) {
  // Check if item is already in list, so we prevent the request if it's not necessary
  const itemFromList = useMemo(
    () => currentListItems?.find((item) => item.ID === id),
    [currentListItems, id],
  );

  const { data, isFetching: isLoading } = useListProductPropertyTypesQuery(
    id !== undefined && !itemFromList
      ? { PageConfig: { Limit: 1, Filter: { ID: id } } }
      : undefined,
    { loaderKey: id !== undefined ? [id] : undefined },
  );

  const productSearchPropertyItem = useMemo(
    () => data?.Result?.Page?.find((item) => item.ID === id),
    [data?.Result?.Page, id],
  );

  const mappedProductSearchProperty = useMemo(
    () =>
      productSearchPropertyItem
        ? { ID: productSearchPropertyItem.ID, Name: productSearchPropertyItem.ID }
        : undefined,
    [productSearchPropertyItem],
  );

  return { data: itemFromList ?? mappedProductSearchProperty, isLoading };
}

export const generateLyraProductSearchPropertySearchListField = ({
  frontendFilter,
}: Partial<
  Pick<
    ISearchListFieldGeneratorProps<
      PIM.ListProductPropertyTypes,
      TProductSearchPropertySearchListFieldItem,
      string
    >,
    "frontendFilter"
  >
>) =>
  SearchListFieldGenerator<
    PIM.ListProductPropertyTypes,
    TProductSearchPropertySearchListFieldItem,
    string
  >({
    frontendFilter,
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    useItemByID: useProductSearchPropertyById,
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((item) => ({
        ID: item.ID,
        Name: item.ID,
      })),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listProductPropertyTypesQuery,
        queryKey: ["ListProductPropertyTypes:SearchListField"],
        initialRequest: {
          PageConfig: {
            Start: 0,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              SearchType:
                ProductPropertyTypeSearchTypes.Keyword + ProductPropertyTypeSearchTypes.Text,
              SearchTypeMatchingMethod:
                ProductPropertyTypeSearchTypeMatchingMethod.MatchesAtLeastOne as number,
            },
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.ID,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              ID: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });
