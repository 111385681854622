import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { uniqBy } from "lodash";
import { v4 as uuid } from "uuid";

import { Autocomplete } from "components/suite-ui/autocomplete";
import RadioButtonList from "components/suite-ui/radio-button-list";
import RadioButtonLabel from "components/suite-ui/radio-button-list/radio-button-label";
import { ProductRequirementDataTypes } from "types/product-requirement-types";

const DISPLAYED_CHIPS_INCREMENT = 5;

type ProducRequirementFieldProps = {
  productRequirement: EVA.Core.Management.ProductRequirementDto;
  productRequirements: EVA.Core.Management.ProductRequirementDto[];
  value?: string[] | number[] | boolean[];
  setValue: (newValue?: string[] | number[] | boolean[]) => void;
  passive?: boolean;
  errorMessage?: string;
  onFieldBlur: () => void;
};

export const EvaProductSetProductRequirementValueField = ({
  errorMessage,
  onFieldBlur,
  passive,
  productRequirement,
  setValue,
  value,
}: ProducRequirementFieldProps) => {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState<string>();
  const [maximumDisplayedSelectedItems, setMaximumDisplayedSelectedItems] =
    useState(DISPLAYED_CHIPS_INCREMENT);

  const fieldType = useMemo(
    () => (productRequirement ? (productRequirement.DataType as number) : undefined),
    [productRequirement],
  );

  const controlledSelectedItem = useMemo(
    () => value?.map((item) => ({ label: item.toString(), value: uuid() })) ?? [],
    [value],
  );

  const parseAutocompleteItem = useCallback(
    (item: string | number | boolean) => {
      switch (fieldType) {
        case ProductRequirementDataTypes.Integer:
          return typeof item === "number"
            ? item
            : // Make sure the input value matches the parsed input
            !isNaN(parseInt(item as string)) && parseInt(item as string).toString() === item
            ? parseInt(item)
            : undefined;
        case ProductRequirementDataTypes.Decimal:
          return typeof item === "number"
            ? item
            : // Make sure the input value matches the parsed input
            !isNaN(parseFloat(item as string)) && parseFloat(item as string).toString() === item
            ? parseFloat(item)
            : undefined;
        case ProductRequirementDataTypes.Bool: {
          return item === "true";
        }
        case ProductRequirementDataTypes.String:
        case ProductRequirementDataTypes.Text:
        case ProductRequirementDataTypes.Enum:
          return item;
        default:
          return undefined;
      }
    },
    [fieldType],
  );

  const handleSelectedItems = useCallback(
    (selectedItems: { label: string; value: string }[]) => {
      const filteredItems = uniqBy([...controlledSelectedItem, ...selectedItems], "label")
        .map((item) => parseAutocompleteItem(item.label))
        .filter((item) => item !== undefined) as string[] | number[] | boolean[]; // We are sure that we have an array of a single type depending on `fieldType`

      setValue(filteredItems);
    },
    [controlledSelectedItem, parseAutocompleteItem, setValue],
  );

  const handleBlur = useCallback(() => {
    if (inputValue) {
      handleSelectedItems([{ label: inputValue, value: inputValue }]);
      setInputValue(undefined);
    }
    onFieldBlur();
  }, [handleSelectedItems, inputValue, onFieldBlur]);

  const handleDeleteItem = useCallback(
    (id: string) => {
      const index = controlledSelectedItem?.findIndex((item) => item.value === id);

      if (index !== undefined) {
        setValue(value?.toSpliced(index, 1));
      }
    },
    [controlledSelectedItem, setValue, value],
  );

  const items = useMemo(
    () =>
      (productRequirement?.DataType as number) === ProductRequirementDataTypes.Enum
        ? (Object.keys(productRequirement?.RequirementOptions?.EnumValues ?? {}) ?? []).map(
            (x) => ({
              value: x,
              label: productRequirement?.RequirementOptions?.EnumValues?.[x],
            }),
          )
        : [],
    [productRequirement?.DataType, productRequirement?.RequirementOptions?.EnumValues],
  );

  const ProductRequirementValueComponent = useMemo(() => {
    switch (fieldType) {
      case ProductRequirementDataTypes.String:
      case ProductRequirementDataTypes.Text:
      case ProductRequirementDataTypes.Integer:
      case ProductRequirementDataTypes.Decimal:
      case ProductRequirementDataTypes.Enum:
        return (
          <Autocomplete
            passive={passive}
            label={intl.formatMessage({ id: "generic.label.values", defaultMessage: "Values" })}
            multi
            freeOptions
            csv
            items={items}
            controlledInputValue={inputValue ?? ""}
            handleInputChange={setInputValue}
            controlledSelectedItem={controlledSelectedItem?.length ? controlledSelectedItem : ""}
            handleSelectedItems={handleSelectedItems}
            handleDeleteItem={handleDeleteItem}
            clearAllItems={() => setValue([])}
            matchKeys={["value"]}
            optionIDKey="value"
            renderOptionValueKey="label"
            error={!!errorMessage}
            helperText={errorMessage}
            onBlur={handleBlur}
            required
            maximumDisplayedSelectedItems={maximumDisplayedSelectedItems}
            onShowMore={() =>
              setMaximumDisplayedSelectedItems((current) => current + DISPLAYED_CHIPS_INCREMENT)
            }
            showMoreText={intl.formatMessage(
              { id: "generic.label.show-more-total", defaultMessage: "Show more ({total} total)" },
              { total: value?.length },
            )}
          />
        );
      case ProductRequirementDataTypes.Bool: {
        const selectedValue = value?.[0] === true ? "true" : "false";

        return (
          <RadioButtonList
            name="bool-product-requirement"
            label={intl.formatMessage({
              id: "generic.label.required-value",
              defaultMessage: "Required value",
            })}
            disabled={passive}
            selectedValue={selectedValue ?? "false"}
            handleSelectedValueChange={(_, value) => setValue([value === "true"])}
          >
            <RadioButtonLabel
              label={intl.formatMessage({
                id: "generic.label.true",
                defaultMessage: "True",
              })}
              value="true"
              activeValue={selectedValue}
            />
            <RadioButtonLabel
              label={intl.formatMessage({
                id: "generic.label.false",
                defaultMessage: "False",
              })}
              value="false"
              activeValue={selectedValue}
            />
          </RadioButtonList>
        );
      }

      default:
        return null;
    }
  }, [
    controlledSelectedItem,
    errorMessage,
    fieldType,
    handleBlur,
    handleDeleteItem,
    handleSelectedItems,
    inputValue,
    intl,
    items,
    maximumDisplayedSelectedItems,
    passive,
    setValue,
    value,
  ]);

  return ProductRequirementValueComponent;
};
