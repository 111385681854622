export default {
  module: {
    path: "/people",
  },
  userRequirementSets: {
    overview: {
      path: "/people/user-requirement-sets",
    },
    create: {
      path: "/people/user-requirement-sets/new",
    },
    duplicate: {
      path: "/people/user-requirement-sets/:id/duplicate",
    },
    details: {
      path: "/people/user-requirement-sets/:id",

      edit: {
        path: "/people/user-requirement-sets/:id/edit",
      },

      delete: {
        path: "/people/user-requirement-sets/:id/delete",
      },

      requirement: {
        edit: {
          path: "/people/user-requirement-sets/:id/:requirementID/edit",
        },

        delete: {
          path: "/people/user-requirement-sets/:id/:requirementID/delete",
        },

        createCustomRequirement: {
          path: "/people/user-requirement-sets/:id/create-custom-requirement",
        },
      },
    },
  },
  employees: {
    overview: {
      path: "/people/employees",
    },
    details: {
      path: "/people/employees/:id",
      disableTwoFactorAuthentication: {
        path: "/people/employees/:id/disable-two-factor-authentication",
      },
      recoverAccount: {
        path: "/people/employees/:id/recover-account",
      },
      temporaryToken: {
        path: "/people/employees/:id/temporary-token",
      },
      ssoOnly: {
        path: "/people/employees/:id/sso-only",
      },
    },
  },
  rolesRights: {
    overview: {
      path: "/people/roles-rights",
    },
    create: {
      path: "/people/roles-rights/new",
    },
    details: {
      path: "/people/roles-rights/:id",
      generalInformation: {
        path: "/people/roles-rights/:id/general-information",
        editGeneralInformation: {
          path: "/people/roles-rights/:id/general-information/edit",
        },
      },
      delete: {
        path: "/people/roles-rights/:id/delete",
      },
      workspaces: {
        path: "/people/roles-rights/:id/workspaces",
        delete: {
          path: "/people/roles-rights/:id/workspaces/:workspaceID/delete",
        },
      },
      modules: {
        path: "/people/roles-rights/:id/modules",
      },
    },
  },
  roleSets: {
    overview: {
      path: "/people/role-sets",
    },
    create: {
      path: "/people/roles-sets/new",
    },
    details: {
      path: "/people/role-sets/:id",
      editGeneralInformation: {
        path: "/people/role-sets/:id/edit-general-information",
      },
      attachRolesToRoleSet: {
        path: "/people/role-sets/:id/attach-roles",
      },
      attachOUSetToRoleSet: {
        path: "/people/role-sets/:id/attach-ou-set",
      },
      deleteAttachedRole: {
        path: "/people/role-sets/:id/delete-attached-role/:role",
      },
    },
  },
  subscriptions: {
    overview: {
      path: "/people/subscriptions",
    },
    create: {
      path: "/people/subscriptions/new",
    },
    details: {
      path: "/people/subscriptions/:id",

      editGeneralInfo: {
        path: "/people/subscriptions/:id/edit-general-info",
      },
    },
  },
  inquiries: {
    overview: {
      path: "/people/inquiries",
    },
    details: {
      path: "/people/inquiries/:id",
    },
  },
  consumers: {
    overview: {
      path: "/people/consumers",
    },
    create: {
      path: "/people/consumers/new",
      addPhoneNumber: {
        path: "/people/consumers/new/add-phone-number",
      },
      editPhoneNumber: {
        path: "/people/consumers/new/edit-phone-number/:phoneNumberId",
      },
    },
    details: {
      path: "/people/consumers/:id",
      generalInfo: {
        path: "/people/consumers/:id/general-info",
        edit: {
          path: "/people/consumers/:id/general-info/edit",
        },
        addAddress: {
          path: "/people/consumers/:id/general-info/add-address",
        },
        editAddress: {
          path: "/people/consumers/:id/general-info/edit-address/:addressId",
        },
        addPhoneNumber: {
          path: "/people/consumers/:id/general-info/add-phone-number",
        },
        editPhoneNumber: {
          path: "/people/consumers/:id/general-info/edit-phone-number/:phoneNumberId",
        },
        editSubscriptions: {
          path: "/people/consumers/:id/general-info/editSubscriptions",
        },
        depositLoyaltyPoints: {
          path: "/people/consumers/:id/general-info/deposit-loyalty-points/:loyaltyProgramID",
        },
        blockUserSubscription: {
          path: "/people/consumers/:id/general-info/block-user-subscription/:subscriptionID",
        },
        unblockUserSubscription: {
          path: "/people/consumers/:id/general-info/unblock-user-subscription/:subscriptionID",
        },
      },
      customFields: {
        path: "/people/consumers/:id/custom-fields",
        addCustomField: {
          path: "/people/consumers/:id/custom-fields/add",
        },
        editCustomField: {
          path: "/people/consumers/:id/custom-fields/:customFieldId",
        },
        deleteCustomField: {
          path: "/people/consumers/:id/custom-fields/:customFieldId/delete",
        },
      },
      company: {
        path: "/people/consumers/:id/company",

        create: {
          path: "/people/consumers/:id/company/create",
        },
      },
      recentOrders: {
        path: "/people/consumers/:id/recent-orders",
      },
      interactionLogs: {
        path: "/people/consumers/:id/interaction-logs",
        addInteractionLog: {
          path: "/people/consumers/:id/interaction-logs/add",
        },
        editInteractionLog: {
          path: "/people/consumers/:id/interaction-logs/edit/:interactionLogId",
        },
        deleteInteractionLog: {
          path: "/people/consumers/:id/interaction-logs/delete/:interactionLogId",
        },
      },
      inquiries: {
        path: "/people/consumers/:id/inquiries",
        details: {
          path: "/people/consumers/:id/inquiries/details/:inquiryID",
        },
      },
      cases: {
        path: "/people/consumers/:id/cases",
      },
      wishlists: {
        path: "/people/consumers/:id/wishlists",
      },
      userCards: {
        path: "/people/consumers/:id/user-cards",
        add: {
          path: "/people/consumers/:id/user-cards/add/:userCardId",
        },
      },
      boughtProducts: {
        path: "/people/consumers/:id/bought-products",
        warranty: {
          path: "/people/consumers/:id/bought-products/warranty/:boughtProductID",
        },
        add: {
          path: "/people/consumers/:id/bought-products/add",
        },
        delete: {
          path: "/people/consumers/:id/bought-products/delete/:boughtProductID",
        },
      },
      personalizedPromotions: {
        path: "/people/consumers/:id/personalized-promotions",
        details: {
          path: "/people/consumers/:id/personalized-promotions/:personalizedPromotionID",
        },
      },
    },
  },

  cases: {
    path: "/people/cases",

    files: {
      overview: {
        path: "/people/cases/files",
      },

      new: {
        path: "/people/cases/files/new",

        searchConsumer: {
          path: "/people/cases/files/new/search-consumer",
        },

        createConsumer: {
          path: "/people/cases/files/new/create-consumer",
        },
      },

      details: {
        path: "/people/cases/files/:id",

        generalInformation: {
          path: "/people/cases/files/:id/general-information",

          edit: {
            path: "/people/cases/files/:id/general-information/edit",
          },

          setAssignee: {
            path: "/people/cases/files/:id/general-information/set-assignee",
          },

          setSummary: {
            path: "/people/cases/files/:id/general-information/set-summary",
          },

          deleteAssignee: {
            path: "/people/cases/files/:id/general-information/delete-assignee",
          },

          setStatus: {
            path: "/people/cases/files/:id/general-information/set-status",
          },

          editConsumer: {
            path: "/people/cases/files/:id/general-information/edit-consumer",

            selectAddress: {
              path: "/people/cases/files/:id/general-information/edit-consumer/select-address/:addressType",
            },

            editAddress: {
              path: "/people/cases/files/:id/general-information/edit-consumer/edit-address/:addressType/:addressID",
            },

            addAddress: {
              path: "/people/cases/files/:id/general-information/edit-consumer/add-address/:addressType",
            },
          },

          addMedia: {
            path: "/people/cases/files/:id/general-information/add-media",
          },

          deleteMedia: {
            path: "/people/cases/files/:id/general-information/delete-media/:blobId",
          },

          customFields: {
            delete: {
              path: "/people/cases/files/:id/general-information/custom-fields/:customFieldId/delete",
            },
            edit: {
              path: "/people/cases/files/:id/general-information/custom-fields/:customFieldId/edit",
            },
          },

          addProduct: {
            path: "/people/cases/files/:id/general-information/add-product",
          },

          addOrder: {
            path: "/people/cases/files/:id/general-information/add-order",
          },

          addProductFromOrder: {
            path: "/people/cases/files/:id/general-information/add-product-from-order",
          },

          addBoughtProduct: {
            path: "/people/cases/files/:id/general-information/add-bought-product",
          },
        },

        repairs: {
          path: "/people/cases/files/:id/repairs",
        },

        interactionLogs: {
          path: "/people/cases/files/:id/interaction-logs",

          delete: {
            path: "/people/cases/files/:id/interaction-logs/:interactionLogId/delete",
          },

          create: {
            path: "/people/cases/files/:id/interaction-logs/new",
          },

          edit: {
            path: "/people/cases/files/:id/interaction-logs/:interactionLogId/edit",
          },
        },
      },
    },

    topics: {
      overview: {
        path: "/people/cases/topics",
      },
      new: {
        path: "/people/cases/topics/new",
      },
      edit: {
        path: "/people/cases/topics/:id/edit-general-info",
      },
      delete: {
        path: "/people/cases/topics/:id/delete",
      },
    },

    statuses: {
      overview: {
        path: "/people/cases/statuses",
      },
      new: {
        path: "/people/cases/statuses/new",
      },
      edit: {
        path: "/people/cases/statuses/:id/edit-general-info",
      },
      delete: {
        path: "/people/cases/statuses/:id/delete",
      },
    },
  },
} as const;
