import { useCallback } from "react";
import { useIntl } from "react-intl";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { toast } from "components/suite-ui/toast";
import useCallService from "hooks/suite-react-hooks/use-call-service";
import { useHandleErrorResponse } from "hooks/suite-react-hooks/use-handle-error-response";

import { IAdHocSetFormValues } from "./types";

const useCreateAdHocOUSet = () => {
  const intl = useIntl();
  const handleError = useHandleErrorResponse();

  const { callService: createOUSetMutation } = useCallService({
    service: CoreManagement.CreateOrganizationUnitSet,
    options: {
      onError: (error) => handleError(error, "CoreManagement.CreateOrganizationUnitSet"),
      onSuccess: (data) => data,
    },
    disableNotifications: true,
  });

  const { callService: createOrUpdateOUSubsetMutation } = useCallService({
    service: CoreManagement.CreateOrUpdateOrganizationUnitSubset,
    options: {
      onError: (error) => handleError(error, "CoreManagement.CreateOrUpdateOrganizationUnitSubset"),
      onSuccess: (data) => data,
    },
    disableNotifications: true,
  });

  const addSubsets = useCallback(
    (
      setId: number,
      subSets: number[],
      type: number,
      errorMessageId: TranslationKey,
      errorMessageDefault: string,
    ) => {
      const promises: Promise<EVA.Core.EmptyResponseMessage | undefined>[] = [];
      subSets.forEach((subSetId) => {
        const createOUSetPromise = createOrUpdateOUSubsetMutation({
          SetID: setId,
          SubsetID: subSetId,
          Type: type,
        }).catch(() => {
          toast.error(
            intl.formatMessage(
              { id: errorMessageId, defaultMessage: errorMessageDefault },
              { organizationUnit: subSetId },
            ),
          );

          return undefined;
        });

        if (createOUSetPromise) {
          promises.push(createOUSetPromise);
        }
      });

      return promises;
    },
    [createOrUpdateOUSubsetMutation, intl],
  );

  const addIncludedSubsets = useCallback(
    (ID: number, includedSubsetsIDs: number[]) => {
      const includedSubsetsPromises: Promise<EVA.Core.EmptyResponseMessage | undefined>[] =
        addSubsets(
          ID,
          includedSubsetsIDs,
          0,
          "create-ouset-form.subsets.included-error",
          "Failed to include organization unit: {organizationUnit}",
        );

      return Promise.all(includedSubsetsPromises);
    },
    [addSubsets],
  );

  const addExcludedSubsets = useCallback(
    (ID: number, excludedSubsetIDs: number[]) => {
      const excludedSubsetsPromises: Promise<EVA.Core.EmptyResponseMessage | undefined>[] =
        addSubsets(
          ID,
          excludedSubsetIDs,
          0,
          "create-ouset-form.subsets.excluded-error",
          "Failed to exclude organization unit: {organizationUnit}",
        );

      return Promise.all(excludedSubsetsPromises);
    },
    [addSubsets],
  );

  const createAdHocOUSet = useCallback(
    (values: IAdHocSetFormValues, callback?: (ID: number) => void) => {
      createOUSetMutation({
        Name: values.name,
        Type: values.type as unknown as EVA.Core.OrganizationUnitSetTypes,
      }).then((result) => {
        if (result?.ID) {
          addIncludedSubsets(result.ID, values.includedOUSetIDs ?? [])
            .finally(() => addExcludedSubsets(result.ID, values.excludedOUSetIDs ?? []))
            .finally(() => {
              toast.success(
                intl.formatMessage({
                  id: "create-ouset-form.subsets.created",
                  defaultMessage: "Organization unit subsets added",
                }),
              );

              callback?.(result.ID);
            });
        }
      });
    },
    [addExcludedSubsets, addIncludedSubsets, createOUSetMutation, intl],
  );

  return createAdHocOUSet;
};

export default useCreateAdHocOUSet;
