import { PIM } from "@springtree/eva-services-pim";

import { createServiceQuery } from "util/query";

// SearchProductPropertyTypes
export const {
  serviceLoaderQuery: searchProductPropertyTypesLoaderQuery,
  serviceQuery: searchProductPropertyTypesQuery,
  useServiceQueryHook: useSearchProductPropertyTypesQuery,
} = createServiceQuery(PIM.SearchProductPropertyTypes, true);

// ListProductPropertyTypes
export const {
  serviceLoaderQuery: listProductPropertyTypesLoaderQuery,
  serviceQuery: listProductPropertyTypesQuery,
  serviceQueryKeys: listProductPropertyTypesQueryKeys,
  useServiceQueryHook: useListProductPropertyTypesQuery,
} = createServiceQuery(PIM.ListProductPropertyTypes, true);

// ListProductPropertyCategories
export const { serviceQuery: listProductPropertyCategoriesQuery } = createServiceQuery(
  PIM.ListProductPropertyCategories,
);
