import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const listBlobsForInvoiceServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  allowEmptyRequest: false,
  key: "Core.ListBlobsForInvoice",
  service: Core.ListBlobsForInvoice,
});

export const listBlobsForInvoice = selectorFamily({
  key: "Core.ListBlobsForInvoice:selector",
  get:
    (parameter) =>
    ({ get }) => {
      const response = get(listBlobsForInvoiceServiceState.response(parameter));

      return response?.Result;
    },
});
