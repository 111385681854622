import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { DefaultValue, selector } from "recoil";

export const listShipmentsToInvoiceServiceState = builders.buildServiceState({
  requireLoggedIn: true,
  allowEmptyRequest: false,
  defaultRequest: undefined,
  key: "Core.ListShipmentsToInvoice",
  service: Core.ListShipmentsToInvoice,
  beforeRequest: ({ req }) => {
    if (!req?.PageConfig?.Filter?.ShipFromOrganizationUnitID) {
      return undefined;
    }

    return req;
  },
});

export const listShipmentsToInvoice = selector({
  key: "Core.ListShipmentsToInvoice:selector",
  get: ({ get }) => get(listShipmentsToInvoiceServiceState.response)?.Result?.Page ?? undefined,
});

export const listShipmentsToInvoiceSkip = selector<number>({
  key: "Core.ListShipmentsToInvoice:selector:skip",
  get: ({ get }) => get(listShipmentsToInvoiceServiceState.request)?.PageConfig?.Start ?? 0,
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(listShipmentsToInvoiceServiceState.request, (state) => ({
        ...state,
        PageConfig: { ...state?.PageConfig, Start: 0 },
      }));
      return;
    }
    set(listShipmentsToInvoiceServiceState.request, (state) => ({
      ...state,
      PageConfig: { ...state?.PageConfig, Start: newValue },
    }));
  },
});

export const listShipmentsToInvoiceLimit = selector<number>({
  key: "Core.ListShipmentsToInvoice:selector:limit",
  get: ({ get }) => get(listShipmentsToInvoiceServiceState.request)?.PageConfig?.Limit ?? 5,
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(listShipmentsToInvoiceServiceState.request, (state) => ({
        ...state,
        PageConfig: { ...state?.PageConfig, Limit: 5 },
      }));
      return;
    }
    set(listShipmentsToInvoiceServiceState.request, (state) => ({
      ...state,
      PageConfig: { ...state?.PageConfig, Limit: newValue },
    }));
  },
});

export const listShipmentsToInvoiceTotal = selector({
  key: "Core.ListShipmentsToInvoice:total",
  get: ({ get }) => get(listShipmentsToInvoiceServiceState.response)?.Result.Total ?? 0,
});

export const listShipmentsToInvoiceShipmentOrderID = selector<number | undefined>({
  key: "Core.ListShipmentsToInvoice:ShipmentOrderID",
  get: ({ get }) => get(listShipmentsToInvoiceServiceState.request)?.PageConfig?.Filter?.OrderID,
  set: ({ set }, newValue) =>
    set(listShipmentsToInvoiceServiceState.request, (state) => ({
      ...state,
      PageConfig: {
        ...state?.PageConfig,
        Start: 0,
        Filter: {
          ...state?.PageConfig?.Filter,
          OrderID: newValue instanceof DefaultValue ? undefined : newValue,
        },
      },
    })),
});

export const listShipmentsToInvoiceShipmentOrderBackendID = selector<string | undefined>({
  key: "Core.ListShipmentsToInvoice:ShipmentOrderBackendID",
  get: ({ get }) =>
    get(listShipmentsToInvoiceServiceState.request)?.PageConfig?.Filter?.OrderBackendID,
  set: ({ set }, newValue) =>
    set(listShipmentsToInvoiceServiceState.request, (state) => ({
      ...state,
      PageConfig: {
        ...state?.PageConfig,
        Start: 0,
        Filter: {
          ...state?.PageConfig?.Filter,
          OrderBackendID: newValue instanceof DefaultValue ? undefined : newValue,
        },
      },
    })),
});

export const listShipmentsToInvoiceShipmentID = selector<number | undefined>({
  key: "Core.ListShipmentsToInvoice:ShipmentID",
  get: ({ get }) => get(listShipmentsToInvoiceServiceState.request)?.PageConfig?.Filter?.ID,
  set: ({ set }, newValue) =>
    set(listShipmentsToInvoiceServiceState.request, (state) => ({
      ...state,
      PageConfig: {
        ...state?.PageConfig,
        Start: 0,
        Filter: {
          ...state?.PageConfig?.Filter,
          ID: newValue instanceof DefaultValue ? undefined : newValue,
        },
      },
    })),
});

export const listShipmentsToInvoiceShipmentBackendID = selector<string | undefined>({
  key: "Core.ListShipmentsToInvoice:ShipmentBackendID",
  get: ({ get }) => get(listShipmentsToInvoiceServiceState.request)?.PageConfig?.Filter?.BackendID,
  set: ({ set }, newValue) =>
    set(listShipmentsToInvoiceServiceState.request, (state) => ({
      ...state,
      PageConfig: {
        ...state?.PageConfig,
        Start: 0,
        Filter: {
          ...state?.PageConfig?.Filter,
          BackendID: newValue instanceof DefaultValue ? undefined : newValue,
        },
      },
    })),
});
