import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listSubscriptionsLoaderQuery,
  serviceQuery: listSubscriptionsQuery,
  serviceQueryKeys: listSubscriptionsQueryKeys,
  useServiceQueryHook: useListSubscriptionsQuery,
} = createServiceQuery(CoreManagement.ListSubscriptions);

export const {
  serviceLoaderQuery: getSubscriptionHandlersLoaderQuery,
  serviceQuery: getSubscriptionHandlersQuery,
  serviceQueryKeys: getSubscriptionHandlersQueryKeys,
  useServiceQueryHook: useGetSubscriptionHandlersQuery,
} = createServiceQuery(CoreManagement.GetSubscriptionHandlers);

export const {
  serviceLoaderQuery: getSubscriptionOrganizationUnitSetsLoaderQuery,
  serviceQueryKeys: getSubscriptionOrganizationUnitSetsQueryKeys,
  useServiceQueryHook: useGetSubscriptionOrganizationUnitSetsQuery,
} = createServiceQuery(CoreManagement.GetSubscriptionOrganizationUnitSets, true);
