import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useHasFunctionality } from "hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "types/functionalities";

import { adminSuiteConfig } from "../../../../admin-suite.config";
import { IChapter } from "../chapter-finder.types";

export const useOrdersChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: ordersChapterAllowed } = useHasFunctionality(
    Functionalities.ORDERS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: orchestrationChapterAllowed } = useHasFunctionality(
    Functionalities.SYMPHONY_SHEETS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: transputJobsChapterAllowed } = useHasFunctionality(
    Functionalities.TRANSPUT_JOBS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: checkoutOptionsChapterAllowed } = useHasFunctionality(
    Functionalities.CHECKOUT_OPTIONS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewOrderStatusAllowed } = useHasFunctionality(
    Functionalities.ORDER_STATUS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewOrderTypesAllowed } = useHasFunctionality(
    Functionalities.ORDER_TYPES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewOrderCustomStatusAllowed } = useHasFunctionality(
    Functionalities.ORDER_CUSTOM_STATUS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewOrderCustomTypesAllowed } = useHasFunctionality(
    Functionalities.ORDER_CUSTOM_TYPES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: orchestrationV2Allowed } = useHasFunctionality(
    Functionalities.SCRIPTS,
    FunctionalityScope.View,
  );

  const ordersChapters = useMemo<IChapter[]>(() => {
    const chapters: IChapter[] = [];

    if (ordersChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.orders.chapters.orders",
          defaultMessage: "Orders",
        }),
        path: "/orders",
      });
    }

    if (orchestrationChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.orders.chapters.orchestration",
          defaultMessage: "Orchestration",
        }),
        path: "/orchestration",
      });
    }

    if (orchestrationV2Allowed) {
      chapters.push({
        title: `${intl.formatMessage({
          id: "module.orders.chapters.orchestration",
          defaultMessage: "Orchestration",
        })} (2.0)`,
        path: "/orchestration-v2",
      });
    }

    if (transputJobsChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.orders.chapters.export-logs",
          defaultMessage: "Export logs",
        }),
        path: "/export-logs",
      });
    }

    if (checkoutOptionsChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.checkout-options",
          defaultMessage: "Checkout options",
        }),
        path: "/checkout-options",
      });
    }

    if (viewOrderStatusAllowed && !adminSuiteConfig.waldoV2) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.orders.chapters.custom-order-statuses",
          defaultMessage: "Custom order statuses",
        }),
        path: "/custom-order-statuses",
      });
    }

    if (viewOrderTypesAllowed && !adminSuiteConfig.waldoV2) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.orders.chapters.custom-order-types",
          defaultMessage: "Custom order types",
        }),
        path: "/custom-order-types",
      });
    }

    if (viewOrderCustomStatusAllowed && adminSuiteConfig.waldoV2) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.orders.chapters.custom-order-statuses",
          defaultMessage: "Custom order statuses",
        }),
        path: "/order-custom-statuses",
      });
    }

    if (viewOrderCustomTypesAllowed && adminSuiteConfig.waldoV2) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.orders.chapters.custom-order-types",
          defaultMessage: "Custom order types",
        }),
        path: "/order-custom-types",
      });
    }

    return chapters;
  }, [
    checkoutOptionsChapterAllowed,
    intl,
    orchestrationChapterAllowed,
    ordersChapterAllowed,
    transputJobsChapterAllowed,
    viewOrderCustomStatusAllowed,
    viewOrderCustomTypesAllowed,
    viewOrderStatusAllowed,
    viewOrderTypesAllowed,
  ]);

  return ordersChapters;
};
