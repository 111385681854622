import { FormattedMessage } from "react-intl";

import { Dialog } from "@material-ui/core";
import { Button, Separator } from "@new-black/lyra";

import Grid from "components/suite-ui/grid";

import { CreateOUSetForm } from "../../create-ouset-form";

interface IAdHocSetModalProps {
  isOpen: boolean;
  onCreated?: (newID: number | undefined) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateOUSetModal = ({ isOpen, onCreated, setIsOpen }: IAdHocSetModalProps) => (
  <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={() => setIsOpen(false)}>
    <CreateOUSetForm
      inModal
      onCreated={onCreated}
      onCancel={() => setIsOpen(false)}
      formActionArea={({ isSubmitting, onCancel, submitForm }) => (
        <>
          <Separator className="my-2" />
          <div className="p-5 pb-[25px]">
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button variant="secondary" onPress={onCancel} isDisabled={isSubmitting}>
                  <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="primary"
                  onPress={submitForm}
                  isLoading={isSubmitting}
                >
                  <FormattedMessage id="generic.label.create" defaultMessage="Create" />
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    />
  </Dialog>
);

export default CreateOUSetModal;
