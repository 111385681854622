import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { Button, SearchListFieldWithCustomTrigger, SvgIcon } from "@new-black/lyra";
import { hooks, state } from "@springtree/eva-sdk-react-recoil";

import useProductIDProperty from "hooks/suite-react-hooks/use-product-id-property";
import { useSearchProductsQueryWithRequest } from "models/products";
import { IProduct } from "types/product";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";

type ProductSearchListFieldWithCustomTriggerProps = {
  addButtonTooltip?: string;
  additionalIncludedFields?: string[];
  onChange?: (value?: IProduct) => void;
};

const ProductSearchListFieldWithCustomTrigger = ({
  addButtonTooltip,
  additionalIncludedFields,
  onChange,
}: ProductSearchListFieldWithCustomTriggerProps) => {
  const applicationConfiguration = hooks.useGetState(state.core.applicationConfigurationState);

  const assetsUrl = useMemo(
    () => (applicationConfiguration?.Configuration["Urls:Assets"] as string) ?? "",
    [applicationConfiguration],
  );

  const getImageUrl = useCallback(
    (productImageBlobID?: string) => {
      if (!!assetsUrl && !!productImageBlobID) {
        return `${assetsUrl}/image/${30}/${30}/${productImageBlobID}.png`;
      }
    },
    [assetsUrl],
  );
  const { getProductIDPropertyValue } = useProductIDProperty();
  const intl = useIntl();

  const { queryResult, setRequest } = useSearchProductsQueryWithRequest(
    {
      PageSize: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
      IncludedFields: [
        "product_id",
        "display_value",
        "primary_image.blob",
        "backend_id",
        "custom_id",
        ...(additionalIncludedFields ?? []),
      ],
    },
    {},
  );

  return (
    <SearchListFieldWithCustomTrigger<IProduct>
      items={queryResult?.data?.Products ?? []}
      onQueryChange={(query) =>
        setRequest((current) => {
          return {
            ...current,
            Query: query,
          };
        })
      }
      onChange={onChange}
      isLoading={queryResult.isFetching}
      label={intl.formatMessage({
        id: "generic.label.product",
        defaultMessage: "Product",
      })}
      placeholder={intl.formatMessage({
        id: "generic.label.search-product",
        defaultMessage: "Search Product",
      })}
      getItemId={(item) => getProductIDPropertyValue(item) ?? ""}
      getLabel={(item) => item.display_value ?? "-"}
      selectRenderElements={(item) => ({
        label: item.display_value ?? "-",
        image: getImageUrl(item.primary_image?.blob),
        description: getProductIDPropertyValue(item)?.toString(),
      })}
    >
      <Button
        variant="icon"
        tooltip={
          addButtonTooltip ?? intl.formatMessage({ id: "generic.label.add", defaultMessage: "Add" })
        }
      >
        <SvgIcon name="plus" />
      </Button>
    </SearchListFieldWithCustomTrigger>
  );
};

export default ProductSearchListFieldWithCustomTrigger;
