import { useIntl } from "react-intl";

import { useGenerateLyraOUSearchListField } from "components/suite-composite/organization-unit-lyra-search-list-field";

import { useGenerateOUSearchListField } from "../../organization-unit-search-list-field";
import { IOuAndSetSelectorFormikProps } from "../types";

import { IOUMultiSelect, IOUOrOUSetSelectorCommonProps, IOUSingleSelect } from "./types";

interface ISingleOUSetSingleSelectorProps extends IOuAndSetSelectorFormikProps, IOUSingleSelect {}
interface ISingleOUSetMultiSelectorProps extends IOuAndSetSelectorFormikProps, IOUMultiSelect {}

const SingleOUSetSelector = ({
  disableSearchListFieldPopoverPortal,
  error,
  helperText,
  label,
  multiOUSelection: multi,
  ouFilters: filters,
  passive,
  required,
  selectedOUID,
  selectedOUIDs,
  setSelectedOUID,
  setSelectedOUIDs,
}: IOUOrOUSetSelectorCommonProps &
  (ISingleOUSetSingleSelectorProps | ISingleOUSetMultiSelectorProps)) => {
  const intl = useIntl();

  const {
    GeneratedMultiOUIDSearchListFieldWithFilters: MultiOuSearchListField,
    GeneratedSingleOUIDSearchListFieldWithFilters: SingleOuSearchListField,
  } = useGenerateOUSearchListField({
    filters,
  });

  return multi ? (
    <MultiOuSearchListField.Controlled
      values={selectedOUIDs}
      onChange={(newValue) => {
        setSelectedOUIDs(newValue?.filter((id): id is number => id !== undefined));
      }}
      label={
        label ??
        intl.formatMessage({
          id: passive
            ? "generic.label.organization-unit"
            : "ouset-selector.single.autocomplete.label",
          defaultMessage: passive ? "Organization unit" : "Select single organization unit",
        })
      }
      required={required}
      error={error}
      helperText={helperText}
      disabled={passive}
      disablePopoverPortal={disableSearchListFieldPopoverPortal}
    />
  ) : (
    <SingleOuSearchListField.Controlled
      value={selectedOUID}
      onChange={setSelectedOUID}
      label={
        label ??
        intl.formatMessage({
          id: passive
            ? "generic.label.organization-unit"
            : "ouset-selector.single.autocomplete.label",
          defaultMessage: passive ? "Organization unit" : "Select single organization unit",
        })
      }
      required={required}
      error={error}
      helperText={helperText}
      disabled={passive}
      disablePopoverPortal={disableSearchListFieldPopoverPortal}
    />
  );
};

export const SingleLyraOUSetSelector = ({
  error,
  helperText,
  label,
  multiOUSelection: multi,
  ouFilters: filters,
  passive,
  required,
  selectedOUID,
  selectedOUIDs,
  setSelectedOUID,
  setSelectedOUIDs,
}: IOUOrOUSetSelectorCommonProps &
  (ISingleOUSetSingleSelectorProps | ISingleOUSetMultiSelectorProps)) => {
  const intl = useIntl();

  const Components = useGenerateLyraOUSearchListField({
    PageConfig: {
      Filter: filters,
    },
  });

  return multi ? (
    <Components.MultiIDSearchListField.Controlled
      values={selectedOUIDs}
      onChange={(newValue) => {
        setSelectedOUIDs(newValue?.filter((id): id is number => id !== undefined));
      }}
      label={
        label ??
        intl.formatMessage({
          id: "generic.label.organization-unit",
          defaultMessage: "Organization unit",
        })
      }
      isRequired={required}
      errorMessage={error && helperText ? helperText : undefined}
      isDisabled={passive}
      selectRenderElements={(item) => ({ label: item.Name, description: item.BackendID ?? "-" })}
    />
  ) : (
    <Components.SingleIDSearchListField.Controlled
      value={selectedOUID}
      onChange={setSelectedOUID}
      label={
        label ??
        intl.formatMessage({
          id: "generic.label.organization-unit",
          defaultMessage: "Organization unit",
        })
      }
      isRequired={required}
      errorMessage={error && helperText ? helperText : undefined}
      isDisabled={passive}
      selectRenderElements={(item) => ({ label: item.Name, description: item.BackendID ?? "-" })}
    />
  );
};

export default SingleOUSetSelector;
