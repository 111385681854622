import { z } from "zod";
import { zfd } from "zod-form-data";

export const loyaltyProgramPaymentTypeFormSchema = (requirePaymentTypeID?: boolean) =>
  z.object({
    BackendID: z.string().optional(),
    Sequence: zfd.numeric(z.number().min(1).optional()),
    Required: zfd.checkbox().optional(),
    PaymentTypeID: requirePaymentTypeID ? zfd.numeric() : zfd.numeric(z.number().optional()),
    TaxHandling: zfd.numeric(z.number().optional()),
    ExcludeFromProgramBudget: zfd.checkbox().optional(),
    Budget: zfd.numeric(z.number().min(0).optional()),
    UserMaximumUsage: zfd.numeric(z.number().min(1).optional()),
    UserBudget: zfd.numeric(z.number().min(0).optional()),
    UserBudgetDeduction: zfd.numeric(z.number().optional()),
    MaximumAmountPerOrder: zfd.numeric(z.number().min(0).optional()),
    MaximumPercentageOfUserBudgetPerOrder: zfd.numeric(z.number().min(0).max(100).optional()),
  });

export const loyaltyProgramPaymentTypeFormKeys =
  loyaltyProgramPaymentTypeFormSchema().keyof().Values;

export type LoyaltyProgramPaymentTypeForm = z.infer<
  ReturnType<typeof loyaltyProgramPaymentTypeFormSchema>
>;

export type LoyaltyProgramPaymentTypeFormErrors = Partial<
  Record<keyof LoyaltyProgramPaymentTypeForm, string>
>;

export const enum LoyaltyPaymentTaxHandlingType {
  InTax = 0,
  UpToTax = 1,
}

export const enum UserBudgetDeduction {
  None = 0,
  Program = 1,
  PaymentMethod = 2,
  Both = 3,
}
