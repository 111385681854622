export const enum ProductPropertyTypeDataTypes {
  None = 0,
  Integer = 1,
  Double = 2,
  Boolean = 3,
  String = 4,
  Blob = 5,
  Date = 6,
  RichText = 7,
}
