import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

export interface IMaskedValue {
  value: string;
}

const MaskedValue = ({ value }: IMaskedValue) => {
  const intl = useIntl();
  const [isVisible, setIsVisible] = useState(false);

  const mask = useMemo(() => "".padEnd(value.length, "*"), [value]);

  return (
    <span className="flex items-center gap-2">
      {isVisible ? value : mask}

      <Button
        variant="icon"
        tooltip={
          isVisible
            ? intl.formatMessage({
                id: "generic.label.hide",
                defaultMessage: "Hide",
              })
            : intl.formatMessage({
                id: "generic.label.show",
                defaultMessage: "Show",
              })
        }
        onPress={() => setIsVisible((current) => !current)}
      >
        {isVisible ? <SvgIcon name="password-visible" /> : <SvgIcon name="password-hidden" />}
      </Button>
    </span>
  );
};

export default MaskedValue;
