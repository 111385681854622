import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { Field, FieldProps, FormikProps, useFormikContext } from "formik";

import { ProductAutocomplete } from "components/suite-composite/product-autocomplete";
import { useDiscountProductRequirements } from "features/discount-edit/views/conditions/use-discount-product-requirements";

import { IUpdateEvaProductSetOptions } from "./eva-product-set.types";
import { EvaProductSetProductRequirementField } from "./eva-product-set-product-requirement-field";
import { EvaProductSetProductRequirementValueField } from "./eva-product-set-product-requirement-value-field.tsx";

export const EVAProductSetProductRequirementFormFields = ({
  onFieldBlur,
  passive,
  uuid,
}: {
  passive?: boolean;
  onFieldBlur: (form: FormikProps<any>) => void;
  uuid: string;
}) => {
  const intl = useIntl();

  const { setFieldValue, values } = useFormikContext<IUpdateEvaProductSetOptions>();

  const { isLoading: isProductRequirementLoading, productRequirements } =
    useDiscountProductRequirements();

  const fieldIndex = useMemo(
    () =>
      values.productRequirements?.findIndex(
        (productRequirement) => productRequirement.uuid === uuid,
      ) ?? -1,
    [uuid, values.productRequirements],
  );

  const currentProductRequirementCondition = useMemo(
    () => (fieldIndex !== -1 ? values.productRequirements?.[fieldIndex] : undefined),
    [fieldIndex, values.productRequirements],
  );

  const selectedProductRequirement = useMemo(
    () =>
      productRequirements?.find(
        (productRequirement) =>
          productRequirement.ID === currentProductRequirementCondition?.ProductRequirementID,
      ),
    [currentProductRequirementCondition?.ProductRequirementID, productRequirements],
  );

  /** Initialize the `ProductID` if we have a `ProductRequirementID` set but the `ProductID` is missing  */
  useEffect(() => {
    if (
      productRequirements &&
      values?.productRequirements?.[fieldIndex].ProductRequirementID &&
      !values?.productRequirements?.[fieldIndex].ProductID
    ) {
      setFieldValue(
        getFieldName(fieldIndex, "ProductID"),
        productRequirements?.find(
          (requirement) =>
            requirement.ID === currentProductRequirementCondition?.ProductRequirementID,
        )?.ProductID,
      );
    }
  }, [
    currentProductRequirementCondition?.ProductRequirementID,
    fieldIndex,
    productRequirements,
    setFieldValue,
    values?.productRequirements,
  ]);

  if (fieldIndex === -1) return null;

  return (
    <div className="flex flex-col gap-5">
      <Field name={getFieldName(fieldIndex, "ProductID")}>
        {({ field, form, meta }: FieldProps) => (
          <ProductAutocomplete
            required
            label={intl.formatMessage({ id: "generic.label.product", defaultMessage: "Product" })}
            familyKey={`Discount:Products:ProductRequirement:Product:${uuid}`}
            selectedProductID={field.value}
            setSelectedProductID={(newValue) => {
              form.setValues({
                ...values,
                productRequirements: values.productRequirements?.map((productRequirement) =>
                  productRequirement.uuid === uuid
                    ? {
                        ...productRequirement,
                        ProductID: newValue,
                        ProductRequirementID: undefined,
                        Values: [],
                      }
                    : productRequirement,
                ),
              });
            }}
            // We only look for product which actually have product requirements
            filters={{ has_product_requirements: { Values: ["true"] } }}
            passive={passive}
            error={!!meta.error && meta.touched}
            helperText={!!meta.error && meta.touched ? meta.error : undefined}
            onBlur={() => onFieldBlur(form)}
          />
        )}
      </Field>

      {currentProductRequirementCondition?.ProductID ? (
        <Field name={getFieldName(fieldIndex, "ProductRequirementID")}>
          {({ form, meta }: FieldProps) => (
            <EvaProductSetProductRequirementField
              productID={currentProductRequirementCondition?.ProductID!}
              value={selectedProductRequirement}
              onChange={(newValue) => {
                form.setValues({
                  ...values,
                  productRequirements: values.productRequirements?.map((productRequirement) =>
                    productRequirement.uuid === uuid
                      ? { ...productRequirement, Values: [], ProductRequirementID: newValue }
                      : productRequirement,
                  ),
                });
              }}
              onFieldBlur={() => onFieldBlur(form)}
              errorMessage={!!meta.error && meta.touched ? meta.error : undefined}
              passive={passive}
              isLoading={isProductRequirementLoading}
            />
          )}
        </Field>
      ) : null}

      {selectedProductRequirement && productRequirements?.length ? (
        <Field name={getFieldName(fieldIndex, "Values")}>
          {({ field, form, meta }: FieldProps) => (
            <EvaProductSetProductRequirementValueField
              productRequirement={selectedProductRequirement}
              productRequirements={productRequirements}
              passive={passive}
              errorMessage={!!meta.error && meta.touched ? meta.error : undefined}
              value={field.value}
              setValue={(newValue) => form.setFieldValue(field.name, newValue, true)}
              onFieldBlur={() => onFieldBlur(form)}
            />
          )}
        </Field>
      ) : null}
    </div>
  );
};

const getFieldName = (index: number, property: "Values" | "ProductRequirementID" | "ProductID") =>
  `productRequirements[${index}].${property}`;
