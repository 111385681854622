import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const getAdditionalAmountsForInvoiceServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  key: "Core:GetInvoiceAdditionalAmountsForInvoice",
  allowEmptyRequest: false,
  service: Core.GetInvoiceAdditionalAmountsForInvoice,
});

export const getAdditionalAmountsForInvoice = selectorFamily({
  key: "Core:GetInvoiceAdditionalAmountsForInvoice:selector",
  get:
    (parameter) =>
    ({ get }) => {
      const response = get(getAdditionalAmountsForInvoiceServiceState.response(parameter));

      return response?.InvoiceAdditionalAmounts;
    },
});

export const getAdditionalAmountsWithConflictsSelector = selectorFamily({
  key: "Core:GetInvoiceAdditionalAmountsWithConflicts:selector",
  get:
    (parameter) =>
    ({ get }) => {
      const additionalAmounts = get(getAdditionalAmountsForInvoice(parameter));

      return additionalAmounts?.filter((item) => item.Amount !== item.OriginalAmount) ?? [];
    },
});
