import { useMemo } from "react";

import { useGetCustomFieldTypeId } from "hooks/use-custom-fields-type-id";
import { useGetCustomFieldMetadataQuery, useGetCustomFieldsQuery } from "models/custom-fields";
import { CustomFieldType } from "types/custom-field";
import { useEditableCustomFields } from "util/hooks/use-editable-custom-fields";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/search-list-field-generator";

export const useCustomFieldSearchListFieldItems = (
  customFieldType: CustomFieldType,
  organizationUnitID?: number,
  externalCustomFieldOptions?: {
    [key: number]: EVA.Core.CustomFieldOptions;
  },
) => {
  const { customFieldTypeID, isLoading: isGetCustomFieldTypeIDLoading } =
    useGetCustomFieldTypeId(customFieldType);

  const { data: customFields, isFetching: isGetCustomFieldsLoading } = useGetCustomFieldsQuery(
    customFieldTypeID && organizationUnitID
      ? { TypeID: customFieldTypeID, OrganizationUnitID: organizationUnitID }
      : undefined,
    { loaderKey: customFieldTypeID ? [customFieldTypeID] : undefined },
  );

  const { data: customFieldMetadata, isFetching: isGetCustomFieldMetadataLoading } =
    useGetCustomFieldMetadataQuery(customFieldTypeID && organizationUnitID ? {} : undefined, {});

  const isLoading = useMemo(
    () =>
      isGetCustomFieldTypeIDLoading || isGetCustomFieldsLoading || isGetCustomFieldMetadataLoading,
    [isGetCustomFieldMetadataLoading, isGetCustomFieldTypeIDLoading, isGetCustomFieldsLoading],
  );

  const customFieldOptions = useMemo(
    () => externalCustomFieldOptions ?? customFields?.CustomFieldOptions,
    [externalCustomFieldOptions, customFields?.CustomFieldOptions],
  );

  const items = useEditableCustomFields(customFieldType, customFieldOptions, customFieldMetadata);

  return { items, isLoading };
};

export const useGenerateCustomFieldSearchListField = (
  customFieldType: CustomFieldType,
  organizationUnitID?: number,
  externalCustomFieldOptions?: {
    [key: number]: EVA.Core.CustomFieldOptions;
  },
  frontendFilter?: (item: EVA.Core.CustomFieldResponse) => boolean,
) => {
  const { isLoading, items } = useCustomFieldSearchListFieldItems(
    customFieldType,
    organizationUnitID,
    externalCustomFieldOptions,
  );

  const {
    MultiIDSearchListField: CustomFieldMultiIDSearchListField,
    MultiSearchListField: CustomFieldMultiSearchListField,
    SingleIDSearchListField: CustomFieldSingleIDSearchListField,
    SingleSearchListField: CustomFieldSearchListField,
  } = useMemo(
    () =>
      SearchListFieldGenerator.LocalSearchListFieldGenerator({
        items: items,
        idKey: "CustomFieldID",
        labelKey: "Name",
        defaultLabel: intlAccessor.formatMessage({
          id: "generic.label.custom-field",
          defaultMessage: "Custom field",
        }),
        useItemByID: (id) => {
          const data = items?.find((customField) => customField.CustomFieldID === id);

          return { data };
        },
        useItemsByID(ids) {
          const data = items?.filter((customField) => ids?.includes(customField.CustomFieldID));

          return { data };
        },
        frontendFilter,
      }),
    [frontendFilter, items],
  );

  return {
    CustomFieldMultiIDSearchListField,
    CustomFieldMultiSearchListField,
    CustomFieldSingleIDSearchListField,
    CustomFieldSearchListField,
    items,
    isLoading,
  };
};
