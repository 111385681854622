import { useListProductRequirementsQuery } from "models/product-requirements";
import { ALL_ROWS_LIMIT, ONE_SECOND } from "util/base-values";

export const useDiscountProductRequirements = (enabled = true) => {
  const { data, isFetching: isLoading } = useListProductRequirementsQuery(
    enabled ? { PageConfig: { Limit: ALL_ROWS_LIMIT } } : undefined,
    { staleTime: ONE_SECOND * 60 * 5, refetchOnMount: false },
  );

  return { productRequirements: data?.Result.Page, isLoading };
};
