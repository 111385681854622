import { useMemo } from "react";

import { useRecoilValue } from "recoil";

import { ProductSetIncludedProductsAtom, ProductSetSelectedAvailableProductsAtom } from "../state";

export function useIsTotalSelectionOfProductsExceedingLimit(
  limit: number,
  previousSelection?: number,
) {
  const currentlySelectedProducts = useRecoilValue(ProductSetSelectedAvailableProductsAtom);
  const alreadySelectedProducts = useRecoilValue(ProductSetIncludedProductsAtom);

  const totalProductsCount = useMemo(
    () =>
      currentlySelectedProducts.length + (previousSelection ?? 0) + alreadySelectedProducts.length,
    [alreadySelectedProducts.length, currentlySelectedProducts.length, previousSelection],
  );

  const exceededLimitCondition = useMemo(
    () =>
      currentlySelectedProducts.length > 0
        ? totalProductsCount > limit
        : totalProductsCount >= limit,
    [currentlySelectedProducts.length, limit, totalProductsCount],
  );

  return exceededLimitCondition;
}
