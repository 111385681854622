import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";

import { Core } from "@springtree/eva-services-core";

import useCallService from "hooks/suite-react-hooks/use-call-service";
import { useHandleErrorResponse } from "hooks/suite-react-hooks/use-handle-error-response";

/** Resend User Interaction Communication Service hook */
const useResendUserInteractionCommunication = (onSuccess?: () => void) => {
  const intl = useIntl();
  const handleError = useHandleErrorResponse();
  const [isLoading, setIsLoading] = useState(false);

  const { callService: resendUnserInteractionCommunicationMutation } = useCallService({
    service: Core.ResendUserInteractionCommunication,
    options: {
      onError: (error) => {
        setIsLoading(false);
        handleError(error, "Core.ResendUserInteractionCommunication");
      },
      onSuccess: () => {
        setIsLoading(false);
        toast.success(
          intl.formatMessage({
            id: "orders.detail.interaction-logs.resend-email-modal.resend.success",
            defaultMessage: "Email resent successfully",
          }),
        );
      },
    },
    disableNotifications: true,
  });

  const resendUnserInteractionCommunication = useCallback(
    async ({ EmailAddress, ID, Text }: { ID: number; EmailAddress: string; Text?: string }) => {
      setIsLoading(true);
      return resendUnserInteractionCommunicationMutation({
        ID,
        Text,
        EmailAddress,
      }).then(() => onSuccess?.());
    },
    [resendUnserInteractionCommunicationMutation, onSuccess],
  );

  return { resendUnserInteractionCommunication, isLoading };
};

export default useResendUserInteractionCommunication;
