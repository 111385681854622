import { Fragment } from "react";

export function QueryableLabel({ label, query }: { label: string; query: string }) {
  const textArray = label.toLowerCase().split(query.toLowerCase());

  return (
    <span>
      {textArray.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {index === 0 && item.length ? item.slice(0, 1).toUpperCase() + item?.slice(1) : item}
          {index !== textArray.length - 1 && (
            <span className="font-bold">
              {index === 0 && query && textArray[0] === ""
                ? query.slice(0, 1).toUpperCase() + query.slice(1)
                : query}
            </span>
          )}
        </Fragment>
      ))}
    </span>
  );
}
