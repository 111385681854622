import { PropsWithChildren } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import Grid from "../grid";
import Text from "../text";

export interface IDropzoneSelectedLabelProps {
  onRemoved: () => void;
  fileUrl?: string;
  removeTooltip?: string;
}

const DropzoneSelectedLabel = ({
  children,
  fileUrl,
  onRemoved,
  removeTooltip,
}: PropsWithChildren<IDropzoneSelectedLabelProps>) => {
  const intl = useIntl();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        {fileUrl ? (
          <a href={fileUrl} rel="noopener noreferrer" target="_blank">
            <Text color="primary">{children}</Text>
          </a>
        ) : (
          <Text>{children}</Text>
        )}
      </Grid>
      <Grid item>
        <Button
          variant="icon-xs"
          onPress={onRemoved}
          tooltip={
            removeTooltip ??
            intl.formatMessage({ id: "generic.label.remove-file", defaultMessage: "Remove file" })
          }
        >
          <SvgIcon name="clear" fontSize="inherit" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default DropzoneSelectedLabel;
