import { QueryClient } from "@tanstack/react-query";

import {
  listProductPropertyTypesLoaderQuery,
  useListProductPropertyTypesQuery,
} from "models/product-search-properties";
import {
  ProductPropertyTypeSearchTypeMatchingMethod,
  ProductPropertyTypeSearchTypes,
} from "types/enums";
import { ALL_ROWS_LIMIT } from "util/base-values";

/**
 * Returns the request object for filtering product property types based on the search type.
 * @param searchType The search type for filtering product property types
 * @returns The request object for filtering product property types
 */
const getPropertyFilteredRequest = (
  searchType: ProductPropertyTypeSearchTypes,
): EVA.PIM.ListProductPropertyTypes => ({
  PageConfig: {
    Start: 0,
    Limit: 50,
    Filter: {
      SearchType: searchType as number,
      SearchTypeMatchingMethod:
        ProductPropertyTypeSearchTypeMatchingMethod.MatchesAtLeastOne as number,
    },
  },
});

/**
 * Reusable function that can be used to prefetch product property types in a loader.
 * This data can then be passed to the {@link useListProductPropertyTypes} hook to get the data.
 * @param queryClient The query client
 * @returns The loader query needed to list product property types
 */
export async function listProductPropertyTypesInLoader(queryClient: QueryClient) {
  const preparedListQuery = listProductPropertyTypesLoaderQuery(
    queryClient,
    getPropertyFilteredRequest(
      ProductPropertyTypeSearchTypes.Keyword + ProductPropertyTypeSearchTypes.Text,
    ),
  );

  const listQuery = await preparedListQuery();

  return {
    listQuery,
  };
}

/**
 * Hook that can be used to list all product property types.
 * This hook will return the data and loading state of the query.
 * If the loader data is passed, it will use it to make use of the already fetched data from the loader.
 * If the loader data is not passed, it will make the query to fetch the data.
 * @param loaderData The data that was returned from the {@link listProductPropertyTypesInLoader} function
 * @returns The data and loading state of the query
 */
export function useListProductPropertyTypes(
  loaderData?: Awaited<ReturnType<typeof listProductPropertyTypesInLoader>>,
) {
  const listQuery = useListProductPropertyTypesQuery(
    loaderData
      ? loaderData.listQuery.request
      : getPropertyFilteredRequest(
          ProductPropertyTypeSearchTypes.Keyword + ProductPropertyTypeSearchTypes.Text,
        ),
    { ...(loaderData?.listQuery.queryProps ?? {}) },
  );

  const data = listQuery.data?.Result?.Page ?? [];
  const isLoading = listQuery.isLoading;
  const isLoadingWithoutResponse = listQuery.isLoading && !listQuery.data;

  return { data, isLoading, isLoadingWithoutResponse };
}
