export default {
  module: {
    path: "/financials",
  },
  invoices: {
    overview: {
      path: "/financials/invoices",
    },
    details: {
      edit: {
        path: "/financials/invoices/edit/:id",
      },
      new: {
        path: "/financials/invoices/new",
      },
      view: {
        path: "/financials/invoices/view/:id",
      },
    },
  },
  payment: {
    wrapper: {
      path: "/financials/payment",
    },

    paymentMethods: {
      overview: {
        path: "/financials/payment/payment-methods",

        add: {
          path: "/financials/payment/payment-methods/add",
        },

        delete: {
          path: "/financials/payment/payment-methods/:id/delete",
        },

        edit: {
          path: "/financials/payment/payment-methods/:id/edit",
        },
      },
    },

    paymentTypes: {
      overview: {
        path: "/financials/payment/payment-types",
      },

      add: {
        path: "/financials/payment/payment-types/add",
      },

      details: {
        path: "/financials/payment/payment-types/:id",

        editGeneralInfo: {
          path: "/financials/payment/payment-types/:id/edit-general-info",
        },

        delete: {
          path: "/financials/payment/payment-types/:id/delete",
        },

        editCustomFieldGeneralInfo: {
          path: "/financials/payment/payment-types/:id/custom-fields/:customFieldId/edit-general-info",
        },

        addCustomField: {
          path: "/financials/payment/payment-types/:id/custom-fields/add",
        },

        deleteCustomField: {
          path: "/financials/payment/payment-types/:id/custom-fields/:customFieldId/delete",
        },
      },
    },

    paymentSettings: {
      overview: {
        path: "/financials/payment/payment-settings",
      },
    },
  },
  priceLists: {
    overview: {
      path: "/financials/price-lists",
    },
    create: {
      path: "/financials/price-lists/new",
    },
    details: {
      path: "/financials/price-lists/:id",
      editGeneralDetails: {
        path: "/financials/price-lists/:id/edit-general-info",
      },
      addOrganizationUnit: {
        path: "/financials/price-lists/:id/organization-units/add",
      },
      editOrganizationUnit: {
        path: "/financials/price-lists/:id/organization-units/:priceListOrganizationUnitId/edit",
      },
      deleteOrganizationUnit: {
        path: "/financials/price-lists/:id/organization-units/:priceListOrganizationUnitId/delete",
      },
      addAdjustment: {
        path: "/financials/price-lists/:id/adjustments/add",
      },
      editAdjustment: {
        path: "/financials/price-lists/:id/adjustments/:adjustmentId/edit",
      },
      addManualAdjustmentProduct: {
        path: "/financials/price-lists/:id/adjustments/:adjustmentId/add-product",
      },
      editManualAdjustmentProduct: {
        path: "/financials/price-lists/:id/adjustments/:adjustmentId/products/:manualAdjustmentProductId/edit",
      },
      uploadManualAdjustmentProducts: {
        path: "/financials/price-lists/:id/adjustments/:adjustmentId/products/upload",
      },
      delete: {
        path: "/financials/price-lists/:id/delete",
      },
    },
  },
  currencies: {
    overview: {
      path: "/financials/currencies",
    },
    details: {
      path: "/financials/currencies/:id",
      addCashHandler: {
        path: "/financials/currencies/:id/cash-handlers/new",
      },
      manageCashHandler: {
        path: "/financials/currencies/:id/cash-handlers/:cashHandlerId",
      },
    },
  },
  exportLogs: {
    overview: {
      path: "/financials/export-logs",
      financialPeriodExports: {
        path: "/financials/export-logs/financial-period-exports",
      },
      invoiceExports: {
        path: "/financials/export-logs/invoice-exports",
      },
    },
  },
  taxManagement: {
    overview: {
      path: "/financials/tax-management",
    },
  },
  incomeAndExpenses: {
    overview: {
      path: "/financials/income-expense",
    },
  },
  giftcards: {
    overview: {
      path: "/financials/giftcards",
    },
    details: {
      path: "/financials/giftcards/:id",
      edit: {
        path: "/financials/giftcards/:id/edit",
      },
    },
    new: {
      path: "/financials/giftcards/new",
    },
  },
  financialPeriods: {
    overview: {
      path: "/financials/financial-periods",
      open: {
        path: "/financials/financial-periods/open",
      },
    },
    details: {
      path: "/financials/financial-periods/:id",

      transactions: {
        path: "/financials/financial-periods/:id/transactions",
      },

      addIncomeExpense: {
        path: "/financials/financial-periods/:id/add-income-expense/:stationId",
      },

      openCashJournal: {
        path: "/financials/financial-periods/:id/open-cash-journal/:deviceId/:paymentTypeId/:currencyId?",
      },

      closeCashJournal: {
        path: "/financials/financial-periods/:id/close-cash-journal/:deviceId/:paymentTypeId/:currencyId?",
      },

      deviceLedgerDetails: {
        path: "/financials/financial-periods/:id/device-ledger-details/:deviceId",
      },

      createDeposit: {
        path: "/financials/financial-periods/:id/create-deposit/:deviceId/:paymentTypeId/:currencyId?",
      },

      completeDeposit: {
        path: "/financials/financial-periods/:id/complete-deposit/:deviceId/:paymentTypeId/:depositID/:currencyId?",
      },

      cashMutation: {
        path: "/financials/financial-periods/:id/cash-mutation/:deviceId/:paymentTypeId/:currencyId?",
      },

      negativeStockImpedimentCreateZCC: {
        path: "/financials/financial-periods/:id/negative-stock-impediments/:productId/:ouId/create-zcc",
      },

      negativeStockImpedimentUpdateStock: {
        path: "/financials/financial-periods/:id/negative-stock-impediments/:productId/:ouId/update-stock",
      },

      closeFinancialPeriod: {
        path: "/financials/financial-periods/:id/close-financial-period",
      },

      downloadBlob: {
        path: "/financials/financial-periods/:id/blobs/:blobId/download",
      },

      viewExport: {
        path: "/financials/financial-periods/:id/exports/:exportId",
      },

      retryExport: {
        path: "/financials/financial-periods/:id/exports/:exportId/retry",
      },
    },
  },

  generalLedgersAndAccounts: {
    path: "/financials/general-ledgers-and-accounts",
    generalLedgers: {
      overview: {
        path: "/financials/general-ledgers-and-accounts/general-ledgers",
        exportGeneralLedgers: {
          path: "/financials/general-ledgers-and-accounts/general-ledgers/export",
        },
      },
    },
    accounts: {
      overview: {
        path: "/financials/general-ledgers-and-accounts/accounts",
        create: {
          path: "/financials/general-ledgers-and-accounts/accounts/new",
        },
        edit: {
          path: "/financials/general-ledgers-and-accounts/accounts/:id/edit",
        },
        delete: {
          path: "/financials/general-ledgers-and-accounts/accounts/:id/delete",
        },
      },
    },
  },

  settlements: {
    overview: {
      path: "/financials/settlements",
    },
    details: {
      path: "/financials/settlements/:id",
    },
    uploads: {
      path: "/financials/settlements/uploads",
      delete: {
        path: "/financials/settlements/uploads/:id/delete",
      },
    },
    uploadSettlement: {
      path: "/financials/settlements/uploads/new",
    },
  },
  cookbookRecipesV1: {
    overview: { path: "/financials/cookbook-recipes-v1" },
    create: {
      path: "/financials/cookbook-recipes-v1/new",
    },
    details: {
      path: "/financials/cookbook-recipes-v1/:id",
      recipe: {
        path: "/financials/cookbook-recipes-v1/:id/recipe",
        editGeneralInformation: {
          path: "/financials/cookbook-recipes-v1/:id/recipe/edit-general-information",
        },
        editRecipe: {
          path: "/financials/cookbook-recipes-v1/:id/recipe/edit-recipe",
        },
      },
      preview: {
        path: "/financials/cookbook-recipes-v1/:id/preview",
        results: {
          path: "/financials/cookbook-recipes-v1/:id/preview/results",
        },
      },
    },
  },
  cookbookRecipes: {
    overview: { path: "/financials/cookbook-recipes" },
    details: {
      path: "/financials/cookbook-recipes/:id",
      recipe: {
        path: "/financials/cookbook-recipes/:id/recipe",
        editGeneralInformation: {
          path: "/financials/cookbook-recipes/:id/recipe/edit-general-information",
        },
        editRecipe: {
          path: "/financials/cookbook-recipes/:id/recipe/edit-recipe",
        },
      },
      preview: {
        path: "/financials/cookbook-recipes/:id/preview",

        results: {
          path: "/financials/cookbook-recipes/:id/preview/results",
        },
      },
    },
    create: {
      path: "/financials/cookbook-recipes/new",
    },
  },

  cookbookEvents: {
    path: "/financials/cookbook-events",

    resetEvents: {
      path: "/financials/cookbook-events/reset",
    },

    processEvents: {
      path: "/financials/cookbook-events/process",
    },
  },
} as const;
