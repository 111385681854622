import { useMemo } from "react";
import { useIntl } from "react-intl";

import * as yup from "yup";

import { OrganizationUnitSetType } from "../utils/types";

const useAdHocSetFormValidationSchema = () => {
  const intl = useIntl();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        type: yup
          .number()
          .oneOf([OrganizationUnitSetType.AdHoc, OrganizationUnitSetType.Custom])
          .required(
            intl.formatMessage({
              id: "create-ouset-form.ouset-type.required",
              defaultMessage: "Type is required",
            }),
          ),
        name: yup.string().required(
          intl.formatMessage({
            id: "create-ouset-form.name.required",
            defaultMessage: "Name is required",
          }),
        ),
      }),
    [intl],
  );

  return validationSchema;
};

export default useAdHocSetFormValidationSchema;
