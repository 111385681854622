import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

import { ALL_ROWS_LIMIT } from "util/base-values";

export const suppliersListServiceState = builders.buildServiceState({
  requireLoggedIn: true,
  allowEmptyRequest: false,
  service: Core.ListOrganizationUnitsDetailed,
  key: "Core.ListOrganizationUnits:Suppliers",
  // TODO: the select input using this could be probably replaced with an autocomplete; see https://n6k.atlassian.net/browse/OPTR-25714
  defaultRequest: { PageConfig: { Start: 0, Limit: ALL_ROWS_LIMIT, Filter: { TypeID: 1024 } } },
});

export const suppliersList = selector({
  key: "Core.ListOrganizationUnits:Suppliers:selector",
  get: ({ get }) => {
    const response = get(suppliersListServiceState.response);

    return response?.Result?.Page;
  },
});
