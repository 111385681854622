import { useCallback, useEffect, useMemo, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { SearchListField } from "@new-black/lyra";
import { isEqual, uniqBy } from "lodash";

import NoImage from "assets/images/no_image.svg?raw";
import Input from "components/suite-ui/input";
import useProductIDProperty from "hooks/suite-react-hooks/use-product-id-property";
import useGetAssetsUrl from "hooks/use-get-assets-url";
import { emptyStringToUndefined } from "util/helper";

import { ProductSummary } from "../product-summary";

import { IBaseProductAutocompleteItem, IProductAutocompleteProps } from "./types";
import useProductAutocomplete from "./use-product-autocomplete";

/** Source: https://gist.github.com/jennyknuth/222825e315d45a738ed9d6e04c7a88d0?permalink_comment_id=4601690#gistcomment-4601690 */
const NoImageSrc =
  "data:image/svg+xml," + NoImage.replace(/[<>#%{}"]/g, (x) => "%" + x.charCodeAt(0).toString(16));

const ProductAutocomplete = <T extends IBaseProductAutocompleteItem>({
  autoFocus,
  disabled,
  error,
  familyKey,
  filters,
  helperText,
  hideInputHeader,
  includedFields,
  label,
  maximumOptions,
  onBlur,
  onFocus,
  passive,
  placeholderImageUrl,
  required,
  selectedProduct,
  selectedProductID,
  setSelectedProduct,
  setSelectedProductID,
  small,
  variant = "material",
}: IProductAutocompleteProps<T>) => {
  const { inputValue, isLoading, products, setInputValue } = useProductAutocomplete({
    selectedProductId: selectedProduct?.product_id ?? selectedProductID,
    filters,
    familyKey,
    includedFields,
    maximumOptions,
  });

  useEffect(() => {
    // update selected product to have the values for all IncludedFields
    if (selectedProduct && setSelectedProduct) {
      const selectedProductValues = products?.find(
        (x) => x.product_id === selectedProduct.product_id,
      );
      if (selectedProductValues && !isEqual(selectedProduct, selectedProductValues)) {
        setSelectedProduct(selectedProductValues);
      }
    }
  }, [products, selectedProduct, setSelectedProduct]);

  const [localSelectedProduct, setLocalSelectedProduct] = useState(selectedProduct);

  // include selected item in the options in case it's not returned by the backend (for example when querying by the product name returns a total > the number of items fetched by the autocomplete)
  const allProducts = useMemo<T[] | undefined>(
    () =>
      localSelectedProduct
        ? uniqBy([...(products ?? []), localSelectedProduct], "product_id")
        : products,
    [localSelectedProduct, products],
  );

  useEffect(() => {
    if (selectedProductID) {
      const selectedProductValues = allProducts?.find(
        (product) => product.product_id === selectedProductID,
      );
      setLocalSelectedProduct(selectedProductValues);
    } else if (selectedProduct) {
      setLocalSelectedProduct(selectedProduct);
    } else {
      setLocalSelectedProduct(undefined);
    }
  }, [allProducts, selectedProduct, selectedProductID]);

  const getOptionLabel = useCallback((option: T) => option.display_value ?? "", []);

  const { getProperty: getIdProp } = useProductIDProperty();

  const getOptionDescription = useCallback(
    (option: T) => getIdProp([option.product_id, option.custom_id, option.backend_id])?.toString(),
    [getIdProp],
  );

  const assetsUrl = useGetAssetsUrl();

  const getOptionImageUrl = useCallback(
    (option: T, imageSize = 30) => {
      if (!!assetsUrl && !!option.primary_image?.blob) {
        return `${assetsUrl}/image/${imageSize}/${imageSize}/${option.primary_image?.blob}.png`;
      }
      return placeholderImageUrl;
    },
    [assetsUrl, placeholderImageUrl],
  );

  if (variant === "lyra") {
    return (
      <SearchListField
        label={label}
        isLoading={isLoading}
        isDisabled={disabled}
        items={allProducts ?? []}
        getLabel={getOptionLabel}
        value={localSelectedProduct}
        isRequired={required}
        getItemId={(item) => item.product_id!}
        onQueryChange={(newQuery) => setInputValue(emptyStringToUndefined(newQuery))}
        selectRenderElements={(item) => ({
          label: getOptionLabel(item),
          description: getOptionDescription(item),
          image: getOptionImageUrl(item) ?? NoImageSrc,
        })}
        hideInputHeader={hideInputHeader}
        onChange={(newValue) => {
          const newSelectedProduct = allProducts?.find(
            (product) => product.product_id === newValue?.product_id,
          );
          setSelectedProduct?.(newSelectedProduct);
          setSelectedProductID?.(newValue?.product_id ?? undefined);
          setLocalSelectedProduct(newSelectedProduct);
        }}
      />
    );
  }

  return (
    <>
      {passive ? (
        <Input passive label={label} value={localSelectedProduct?.display_value || ""} />
      ) : (
        <Autocomplete
          loading={isLoading}
          handleHomeEndKeys
          onBlur={onBlur}
          onFocus={onFocus}
          clearOnBlur
          selectOnFocus
          autoHighlight
          disabled={disabled}
          value={localSelectedProduct?.product_id ?? null}
          onChange={(_, newValue) => {
            const newSelectedProduct = allProducts?.find(
              (product) => product.product_id === newValue,
            );
            setSelectedProduct?.(newSelectedProduct);
            setSelectedProductID?.(newValue ?? undefined);
            setLocalSelectedProduct(newSelectedProduct);
          }}
          inputValue={inputValue || localSelectedProduct?.display_value || ""}
          onInputChange={(_, newValue) => {
            setInputValue(newValue || undefined);
          }}
          options={allProducts?.map((product) => product.product_id) ?? []}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            return allProducts?.find((x) => x.product_id === option)?.display_value ?? "";
          }}
          renderOption={(value?: number) => {
            const product = allProducts?.find((x) => x.product_id === value);

            if (!product) {
              return value;
            }

            return (
              <ProductSummary
                productName={product.display_value ?? ""}
                productID={product.product_id}
                customID={product.custom_id}
                backendID={product.backend_id}
                productImageBlobID={product.primary_image?.blob}
                placeholderImageUrl={placeholderImageUrl}
                imageSize={30}
              />
            );
          }}
          renderInput={(props) => (
            <Input
              {...props}
              autoFocus={autoFocus}
              label={label}
              error={error}
              helperText={helperText}
              required={required}
              small={small}
              InputProps={{
                ...props.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {props.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          getOptionSelected={(option, value) => option === value}
          filterOptions={(options) => options}
        />
      )}
    </>
  );
};

export default ProductAutocomplete;
